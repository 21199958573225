import {Component, Input, OnInit} from '@angular/core';
import {Ipaginate} from "../../models/Ipaginate";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input() data: Ipaginate;

  constructor() { }

  ngOnInit(): void {
  }

}
