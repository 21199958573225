
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
            <a class="navbar-brand mr-5" routerLink='/'>
                <img src="assets/img/logo-white.png"/>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span>
                <i class="fa fa-bars btn text-white font20"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
              <ul class="navbar-nav text-capitalize ml-auto">
                <li class="nav-item">
                  <a class="nav-link text-bold mr-2" routerLink='/'>Dashboard</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink='/users'>Users</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink='/payments'>Payments</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-3" routerLink='/approval'>Approval</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-3" routerLink='/images'>Images</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-3" routerLink='/settings'>Settings</a>
                </li>
              </ul>
              <div class="nav-item ml-auto d-flex align-items-center" routerLink='/'>
<!--                <div class="nav-profile-img">-->
<!--                  <img src='assets/img/profile.png' class="w-100"/>-->
<!--                </div>-->
                  <span class=" ml-2 mr-3 text-white font14">{{adminService.admin.firstname}}</span>
                  <span class="notification-icon p-r">
                    <i class="fa fa-bell text-white font18"></i>
<!--                    <span class="badge">3</span>-->
                  </span>
                <span (click)="adminService.signOut()" class="click pl-3">
                  <i class="fa fa-sign-out text-white font18"></i>
                </span>
                </div>
            </div>
            </div>
          </nav>
