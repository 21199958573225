<app-navbar></app-navbar>

  <ngx-spinner type="ball-atom" *ngIf="adminService.loading"></ngx-spinner>

  <section class="container mt-5">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <h3 class="font26 text-grey font-light">Welcome,<span class="font-bold">{{adminService.admin.firstname}}</span></h3>
      </div>
      <div class="col-lg-2 col-sm-12 "></div>
<!--      <div class="col-lg-4 col-sm-12 sm-mt">-->
<!--        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link active"-->
<!--              id="pills-today-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#pills-today"-->
<!--              role="tab"-->
<!--              aria-controls="pills-today"-->
<!--              aria-selected="true"-->
<!--            >Today <br />-->
<!--              <div class="small-line text-center mt-1"></div-->
<!--              ></a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link"-->
<!--              id="pills-week-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#pills-today"-->
<!--              role="tab"-->
<!--              aria-controls="pills-week"-->
<!--              aria-selected="false"-->
<!--            >Week <br />-->
<!--              <div class="small-line text-center mt-1"></div-->
<!--              ></a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link"-->
<!--              id="pills-month-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#pills-today"-->
<!--              role="tab"-->
<!--              aria-controls="pills-month"-->
<!--              aria-selected="false"-->
<!--            >Month <br />-->
<!--              <div class="small-line text-center mt-1"></div-->
<!--              ></a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link"-->
<!--              id="pills-allTime-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#pills-today"-->
<!--              role="tab"-->
<!--              aria-controls="pills-allTime"-->
<!--              aria-selected="false"-->
<!--            >All Time <br />-->
<!--              <div class="small-line text-center mt-1"></div-->
<!--              ></a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-today"
        role="tabpanel"
        aria-labelledby="pills-today-tab"
      >
        <div class="row">
          <div class="col-lg-3 col-sm-12">
            <div class="dashboard-card  px-4 py-3 bg-white">
              <div class="text-right mb-3">
                <span class="badge manage-badge click" routerLink="/users" >Manage</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-4 font14 font-bold green">
                  Users
                </div>
                <div>
                  <h3 class="font26 font-light text-grey mb-0">{{data?.users_count}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="dashboard-card  px-4 py-3 bg-white">
              <div class="text-right mb-3">
                <span class="badge manage-badge click" routerLink="/images">Manage</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-4 font14 font-bold green">
                  Photos
                </div>
                <div>
                  <h3 class="font26 font-light text-grey mb-0">{{data?.published_images_count}}</h3>
                  <span class="font12 font-light text-grey mb-0">{{data?.images_count}} (draft)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="dashboard-card  px-4 py-3 bg-white">
              <div class="text-right mb-3">
                <span class="badge manage-badge click" routerLink="
/payments">Manage</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-4 font14 font-bold green">
                  Sales
                </div>
                <div>
                  <h3 class="font26 font-light text-grey mb-0">{{data?.sales_count}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="dashboard-card  px-4 py-3 bg-white">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-4 font14 font-bold green mb-3">
                  Payments
                </div>
                <div class="text-right mb-3">
                  <span class="badge manage-badge click" routerLink="/payments">Manage</span>
                </div>
              </div>
              <div>
                <div>
                  <h3 class="font26 font-light text-grey mb-0">NGN {{data?.payments_value}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="container my-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="font26 font-bold text-grey mt-5 mb-3">Recent Users</h3>
      <div class="btn btn-view text-black" routerLink="/users">View All</div>
    </div>
    <div class="table-responsive mb-5">
      <table datatable class="table text-nowrap w-100 table-borderless">
        <thead class="text-grey">
        <tr>
          <th>S/N</th>
          <th>Customer Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
<!--          <th>Downloaded</th>-->
          <th>Uploaded</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="font14 text-black">
        <tr class="click" *ngFor="let user of users;let i = index">
          <td>
<!--            <label class="check-container">-->
<!--              <input type="checkbox" />-->
<!--              <span class="checkmark"></span>-->
<!--            </label>-->
            {{i + 1}}
          </td>
          <td>
            {{user.firstname}} {{user.surname}}
          </td>
          <td>{{user.email}}</td>
          <td>{{user.phone}}</td>
<!--          <td class="text-center">-->
<!--            3-->
<!--          </td>-->
          <td class="text-center">
            {{user.contribution_count}}
          </td>
          <td>
<!--            <div class="btn btn-view text-black" routerLink="/user-details">-->
<!--              View-->
<!--            </div>-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>

  <section class="container my-5">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="font26 font-bold text-grey mt-5 mb-3">Recent Payments</h3>
      <div class="btn btn-view text-black" routerLink="/payments">View All</div>
    </div>
    <div class="table-responsive mb-5">
      <table datatable class="table text-nowrap w-100 table-borderless">
        <thead class="text-grey">
        <tr>
          <th>S/N</th>
          <th>Photo</th>
          <th>Amount (NGN)</th>
          <th>Customer</th>
          <th>Photographer</th>
          <th>Date</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="font14 text-black">
        <tr class="click" *ngFor='let payment of payments;let i = index;'>
          <td>
            {{i + 1}}
          </td>
          <td>
            {{payment.image?.name}}
          </td>
          <td>{{payment.amount}}</td>
          <td>{{payment.user?.firstname}} {{payment.user?.surname}}</td>
          <td>
            {{payment.image?.user.firstname}} {{payment.image?.user.surname}}
          </td>
          <td>
            {{payment.created_at}}
          </td>
          <td>
<!--            <div class="btn btn-view text-black" routerLink='/payment-details'>View</div>-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </section>



<app-footer class="mt-auto"></app-footer>
