import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {AdminService} from "../../services/admin/admin.service";
import {Ipayment} from "../../models/Ipayment";
import {Ipaginate} from "../../models/Ipaginate";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  payments:Ipayment[];
  data: Ipaginate;
  page;



  constructor(
    public adminService : AdminService,
    public toastr : ToastrService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getPayments();
  }

  getPayments(){
    let data = {
      page : this.page
    };
    this.spinner.show();

    this.adminService.payments(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.data = success.data.payments;
      this.page = this.data.current_page;
      this.payments = success.data.payments.data;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

  setPage(page){
    this.page = page;
    this.getPayments();
  }
}
