<app-navbar></app-navbar>

<ngx-spinner type="ball-atom" *ngIf="adminService.loading"></ngx-spinner>

<section class="container mt-5">
  <div class="row">

<section class="container my-5">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="font26 font-bold text-grey mt-5 mb-3">Manage Settings</h3>
<!--    <div class="btn btn-view text-black" routerLink="/payments">View All</div>-->
  </div>

  <div class="table-responsive mb-5">
    <table datatable class="table text-nowrap w-100 table-borderless">
      <thead class="text-grey">
      <tr>
        <th>S/N</th>
        <th>Name</th>
        <th>Value</th>
        <th>Date</th>
      </tr>
      </thead>
      <tbody class="font14 text-black">
      <tr class="click" *ngFor='let setting of settings;let i = index;'>
        <td>
          {{i + 1}}
        </td>
        <td *ngIf="setting.name == 'card_addition_charge'">
          Card Addition Charge
        </td>
        <td *ngIf="setting.name == 'transfer_charge'">
          Transfer Charge
        </td>
        <td *ngIf="setting.name == 'platform_percent'">
         Platform Percent
        </td>
        <td *ngIf="setting.name == 'image_cost'">
         Image Cost
        </td>
        <td>{{setting.value}}</td>
        <td>{{setting.created_at}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="bg-white col-lg-6 col-sm-12 offset-lg-3 p-3 rounded">

      <div class="row">
        <div class="col-12">
          <label class="m-2">Name</label>
          <select class="form-control ml-2 mr-2" (change)="selectName($event)">
            <option value="platform_percent">Platform Percent</option>
            <option value="card_addition_charge">Card Addition Charge</option>
            <option value="image_cost">Image Cost</option>
          </select>
        </div>

        <div class="col-12">
          <label class="m-2">Value</label>
          <input class="form-control ml-2 mr-2" type="text" [(ngModel)]="value">
        </div>

        <button class="btn btn-success ml-4 mt-2 mb-2" (click)="addSetting()">Save</button>
      </div>

  </div>
</section>


  </div>

<app-footer class="mt-auto"></app-footer>
