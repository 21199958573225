<app-navbar></app-navbar>
<ngx-spinner type="ball-atom"></ngx-spinner>
<section class="py-5 container">
  <h3 class="font24 text-grey font-bold">Image <span *ngIf="image">#{{image.imid}}</span></h3>
</section>
<section class="container">

  <div class="row font14 bold font-lato search-body active bg-white">
    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="image" >
      <div class="bg-white my-4 border-radius click" >
        <div class="img-box browse-image">
          <img [src]="image.preview" alt="" class='w-100'/>
        </div>
      </div>

    </div>
    <div *ngIf="image" class="bg-white ml-0 row col-lg-12 font-light">
      <div class="p-3 row col-lg-12">
        <div class="col-sm-12 col-lg-6">

          <label *ngIf="image.status == 'approved'" class="badge font16 p-2 font-light uppercase badge-success">{{image.status}}</label>
          <label *ngIf="image.status == 'rejected'" class="badge font16 p-2 font-light  badge-danger">{{image.status}}</label>
          <label *ngIf="image.status == 'pending'" class="badge font16 p-2 font-light uppercase badge-warning">{{image.status}}</label>

          <p *ngIf="image.status == 'approved'">
          <p>Reviewed By : {{image.reviewed_by}} <br>
          <p *ngIf="image.reason != ''">Reason: <br>{{image.reason}}</p>
          <p *ngIf="image.reviewed_by == 'staff'">Staff Name : {{image.staff.firstname}} {{image.staff.surname}}
          </p>
          <p>Name: {{image.name}}</p>
          <p>Description : <br>
            {{image.description}}
          </p>
          <p>Location: {{image.location}}</p>
          <p>Tags:           <br>  <span class="badge font14" *ngFor="let tag of image.tags">{{tag}},</span>
          </p>


          <div *ngFor="let attribute of image.attributes">
            <div >
              <p class="mb-1">{{attribute.name}}</p>
            </div>
            <div>
              <p class="mb-1">{{attribute.value}}</p>

            </div>
          </div>


          <p *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>
          <p *ngIf="image.user.brand">By {{image.user.brand}}</p>
        </div>
        <div class="col-sm-12 col-lg-6" *ngIf="image.status == 'pending'" >
          <textarea class="form-control mb-2" [(ngModel)]="reason" placeholder="Please enter a reason if you are rejecting a photo"></textarea>
          <button (click)="approveImage()" class="btn btn-success click">Approve</button>
          <button class="btn btn-danger click ml-1"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  (click)="rejectImage()"
          >Reject</button>

        </div>
      </div>
    </div>

    <!--        <div class="col-lg-4 col-md-6 col-sm-12" *ngFor='let image of images'>-->

    <!--&lt;!&ndash;          <div class="bg-white my-4 border-radius box-shadow" routerLink='/pictureDetail'>&ndash;&gt;-->
    <!--&lt;!&ndash;                <div class="img-box">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <img src="assets/img/Group9.png" alt="" class='w-100'/>&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                <div class="p-3 d-flex align-items-center justify-content-between">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <p>Boy wearing blue shirt sitting on swing.</p>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <p>By Daniel Fosh</p>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <img src="assets/img/Group.png" alt=""/>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--&lt;!&ndash;            </div>&ndash;&gt;-->
    <!--        </div>-->
  </div>
</section>

<!-- delete Selected Modal -->
<div
  class="modal fade"
  id="deleteSelectedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header px-4">
        <h5 class="modal-title font20" id="deleteSelectedModalLabel">Confirm Rejection</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-5">
        <p class="font-light text-center">
          Are you sure you want to reject this picture? Click the ‘No’ button to
          cancel this action
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn bg-white text-red border-red"  data-dismiss="modal">
          No
        </button>
        <button class="btn bg-red text-white" data-dismiss="modal" (click)="rejectImage()">Yes</button>
      </div>
    </div>
  </div>
</div>

<app-footer class="mt-auto"></app-footer>



