import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public env  =  'local';
  public url;
  public inlinePaymentUrl;
  public version = 'v1';
  public admin;
  public isLoggedIn = false;
  public authToken = '';
  public loading = false;
  public nextUrl;
  public publicKey = '';
  public image;

  constructor(public http: HttpClient,public router: Router) {
    this.setup();
  }

  setup() {
   this.url = environment.apiUrl + this.version + '/admin/';
   this.inlinePaymentUrl =  environment.inlinePaymentUrl;
   this.publicKey =  environment.publicKey;

   this.isAdminLoggedIn();

  }

  setAdmin(admin){
    this.admin = admin;
    this.isLoggedIn = true;
    window.localStorage.setItem("admin",JSON.stringify(admin));
    window.localStorage.setItem("orb-token",this.authToken);
  }

  getApiFormattedDate(date){
    let month = date.getMonth() + 1;
    return date.getDate() + '-' + month + '-' + date.getFullYear();
  }

  signOut(){
    this.admin = null;
    this.isLoggedIn = false;
    this.router.navigate(['/sign-in'])
    window.localStorage.removeItem("admin");
  }

  isAdminLoggedIn(){
    this.isLoggedIn = localStorage.getItem("orb-token") !== null;
    let admin = localStorage.getItem("admin");
    let token = localStorage.getItem("orb-token");

    if( admin !==  null){
      admin = JSON.parse(admin);
      this.authToken = token;
      this.setAdmin(admin);
    }
  }
  signUp(admin) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(admin);
    return this.http.post(this.url + "sign-up",body,{headers});
  }

  signIn(admin) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(admin);
    return this.http.post(this.url + "sign-in",body,{headers});
  }

  // authorized routes

  dashboard(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "dashboard",body,{headers});
  }

  users(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "all-users",body,{headers});
  }

  payments(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "payments",body,{headers});
  }

  addSetting(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "add-setting",body,{headers});
  }

  settings(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "settings",body,{headers});
  }

  imagesDetails(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "image-details",body,{headers});
  }

  deleteImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "delete-image",body,{headers});
  }

  pendingImages(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "pending-images",body,{headers});
  }

  images(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "images",body,{headers});
  }

  approveImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "approve-image",body,{headers});
  }

  rejectImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "reject-image",body,{headers});
  }



  // getUpdatedProfile(){
  //   let data = {
  //
  //   };
  //   this.profile(data).subscribe((success:any)=>{
  //     this.admin = success.data.admin;
  //     this.admin.cards = success.data.cards;
  //     this.admin.accounts = success.data.accounts;
  //     this.admin.withdrawals = success.data.withdrawals.data;
  //
  //   });
  //
  // }

  handleAuthError(error){
    console.log(error);
    if(error.status == 401){
      this.signOut();
    }
  }
}


