import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin/admin.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-image-details',
  templateUrl: './image-details.component.html',
  styleUrls: ['./image-details.component.css']
})
export class ImageDetailsComponent implements OnInit {

  image;
  imid;
  reason = '';

  constructor(
    public adminService : AdminService,
    public toastr : ToastrService,
    public spinner: NgxSpinnerService,
    public route : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.imid = this.route.snapshot.paramMap.get("id");
    this.image = this.adminService.image;
    this.getImage();
  }


  getImage(){
    let data = {
      imid : this.imid
    };
    this.spinner.show();

    this.adminService.imagesDetails(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.image = success.data.image;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  rejectImage(){
    let data = {
      imid : this.imid,
      reason : this.reason
    };

    if(this.reason == '') {
      this.toastr.error('Please enter a reason');
      return;
    }

    this.spinner.show();

    this.adminService.rejectImage(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.toastr.success(success.message);
      this.getImage();

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  approveImage(){
    let data = {
      imid : this.imid
    };
    this.spinner.show();

    this.adminService.approveImage(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.toastr.success(success);

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }

}
