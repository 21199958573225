import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UsersDetailsComponent } from './pages/users-details/users-details.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { UsersComponent } from './pages/users/users.component';
import { PaymentDetailsComponent } from './pages/payment-details/payment-details.component';
import { ImagesComponent } from './pages/images/images.component';
import {AuthGuard} from "./auth/auth.guard";
import {AppComponent} from "./app.component";
import {SignInComponent} from "./auth/sign-in/sign-in.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {ImageDetailsComponent} from "./pages/image-details/image-details.component";
import {ApprovalComponent} from "./pages/approval/approval.component";


const routes: Routes = [

  {path: "", redirectTo : "/dashboard",pathMatch: "full"},
  {path: 'sign-in', component: SignInComponent},
  {path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
    children : [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'admin-details', component: UsersDetailsComponent},
      {path: 'users', component: UsersComponent},
      {path: 'payments', component: PaymentComponent},
      {path: 'payment-details', component: PaymentDetailsComponent},
      {path: 'approval', component: ApprovalComponent},
      {path: 'images', component: ImagesComponent},
      {path: 'image/:id', component: ImageDetailsComponent},
      {path: 'settings', component: SettingsComponent},
      {path: "", redirectTo : "sign-in",pathMatch: "full"},

    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
