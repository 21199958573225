<div class="login">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" ></mat-progress-bar>
  <nav class="bg-black py-3">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="logo pointer" routerLink='/'>
          <img src="assets/img/logo-white.png" alt="" class="w-100" />
        </div>
      </div>
    </div>
  </nav>
  <section class="p-r">
    <div class="pattern3">
      <img src="assets/img/Ellipse1.png" alt="" />
    </div>
    <div class="pattern4">
      <img src="assets/img/line.png" alt="" />
    </div>
    <div class="container">
      <div class="row" style="padding-top: 14vh;">
        <div class="col-lg-6 col-sm-12 my-auto sm-d-none">
          <h3 class="text-black font-bold font64">
            Admin Portal
          </h3>
          <p class="font21">
            Take advantage of our exclusive platform that allows you access
            afrocentric images and for use in your creative projects.
          </p>
        </div>
        <div class="col-lg-1 col-sm-12"></div>
        <div class="col-lg-5 col-sm-12" style="z-index: 3;">
          <div class="form-card p-r">
            <div class="pattern1">
              <img src="assets/img/pattern1.png" alt="" />
            </div>
            <h4 class="font30 mb-4">Sign In</h4>
            <form>
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Email</span>
                <!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                placeholder="Email"
                class="form-control mt-1 mb-4"
                name="email"
                [(ngModel)]="admin.email"
              />
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Password</span>
                <!--                <span><a href="#"> Forgot</a></span>-->
              </div>
              <input
                type="password"
                placeholder="******* "
                class="form-control mt-1 mb-4"
                name="password"
                [(ngModel)]="admin.password"
              />
              <button type="submit" class="btn form-control" (click)="signIn()">
                Sign In
              </button>
            </form>
            <div class="pattern2">
              <img src="assets/img/Ellipse2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
<app-footer class="mt-auto"></app-footer>
