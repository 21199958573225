<app-navbar></app-navbar>
<section class="mt-5 mb-3 container">
  <h3 class="font26 font-bold text-grey mt-5">Payments</h3>
  <div class="row align-items-center bg-white sm-reverse border-radius">
    <!-- <div
      class="
        text-green
        font12
        font-bold
        col-lg-2 col-sm-12
        sm-text-center sm-mt sm-mb-2
      "
    >
      ADD FILTER
    </div>
    <div class="table-search col-lg-7 col-sm-12">
      <input type="text" placeholder="Search Payments" />
    </div>
    <div class="sort col-lg-3 col-sm-12 sm-mt sm-mb-2 sm-text-center">
      <span class="font-bold font14 text-grey mr-3">SORT BY</span>
      <span class="dropdown click">
        <span
          class="font-bold text-green font12 dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          >Name <i class="fa fa-chevron-down"></i
        ></span>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item click line-under">Name</a>
          <a class="dropdown-item click">name</a>
          <a class="dropdown-item click">name</a>
        </div>
      </span>
    </div> -->
  </div>
</section>

<section class="container">
  <div class="table-responsive mb-5">
    <table datatable class="table text-nowrap w-100 table-borderless">
      <thead class="text-grey">
        <tr>
          <th>S/N</th>
          <th>Photo</th>
          <th>Amount (NGN)</th>
          <th>Customer</th>
          <th>Photographer</th>
          <th>Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="font14 text-black">
        <tr class="click" *ngFor="let payment of payments; let i = index">
          <td>
            {{ data.from + i }}
          </td>
          <td>
            {{ payment.image?.name }}
          </td>
          <td>{{ payment.amount }}</td>
          <td>{{ payment.user?.firstname }} {{ payment.user?.surname }}</td>
          <td>
            {{ payment.image?.user.firstname }}
            {{ payment.image?.user.surname }}
          </td>
          <td>
            {{ payment.created_at }}
          </td>
          <td>
            <!--            <div class="btn btn-view text-black" routerLink='/payment-details'>View</div>-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-center my-5 list-nav" *ngIf="data">
    <div class="d-flex align-items-center sm-flex-column">
      <div class="prev font-bold font20 click" (click)="setPage(page - 1)">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="numbers sm-mr-none sm-ml-none">
        <span
          class="click"
          *ngIf="data.current_page - 4 > 0"
          (click)="setPage(data.current_page - 4)"
          >{{ data.current_page - 4 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 3 > 0"
          (click)="setPage(data.current_page - 3)"
          >{{ data.current_page - 3 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 2 > 0"
          (click)="setPage(data.current_page - 2)"
          >{{ data.current_page - 2 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 1 > 0"
          (click)="setPage(data.current_page - 1)"
          >{{ data.current_page - 1 }}</span
        >
        <span class="active">{{ data.current_page }}</span>
        <span
          class="click"
          *ngIf="data.current_page + 1 <= data.last_page"
          (click)="setPage(data.current_page + 1)"
          >{{ data.current_page + 1 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 2 <= data.last_page"
          (click)="setPage(data.current_page + 2)"
          >{{ data.current_page + 2 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 3 <= data.last_page"
          (click)="setPage(data.current_page + 3)"
          >{{ data.current_page + 3 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 4 <= data.last_page"
          (click)="setPage(data.current_page + 4)"
          >{{ data.current_page + 4 }}</span
        >
      </div>
      <div class="next font-bold font20 click" (click)="setPage(page + 1)">
        <i class="fa fa-angle-right"></i>
      </div>
    </div>
  </div>
</section>

<app-footer class="mt-auto"></app-footer>
