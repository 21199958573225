<section class="py-5 container">
<h3 class="font20 click" routerLink='/users'> <i class="fa fa-arrow-left mr-3"></i>Back to Users</h3>
<div class="my-5">
    <div class="row">
        <div class="col-lg-3 col-sm-12">
            <div class="card p-4 bg-black">
                <div class="d-flex align-items-center">
                  <div class="mr-4">
                    <img src="assets/img/user.png" alt="" />
                  </div>
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">30</h3>
                    <h5 class="font14 font-bold text-white">Photos</h5>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="card p-4 bg-brown">
                <div class="d-flex align-items-center">
                  <div class="mr-4">
                    <img src="assets/img/camera.png" alt="" />
                  </div>
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">30</h3>
                    <h5 class="font14 font-bold text-white">Photos</h5>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="card p-4 bg-green">
                <div class="d-flex align-items-center">
                  <div class="mr-4">
                    <img src="assets/img/camera.png" alt="" />
                  </div>
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">30</h3>
                    <h5 class="font14 font-bold text-white">Photos</h5>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-lg-3 col-sm-12 sm-mt">
            <div class="card p-4 bg-red">
              <div>
                <h3 class="font32 font-bold text-white mb-0">N100,000</h3>
                <h5 class="font14 font-bold text-white">Today</h5>
              </div>
            </div>
        </div>
    </div>
</div>
</section>

<section class="container py-5">
    <div class="row">
        <div class="col-lg-3 col-sm-12">
          <div
            class="bg-black pb-3 pt-4 text-center"
            style="border-radius:6px 6px 0px 0px; "
          >
            <div class="profile-img mx-auto">
              <img src="assets/img/Oval.png" alt="" />
            </div>
            <p class="mt-2 font24 font-bold text-white ">Sarah<br />Shaibu</p>
          </div>
          <div
            class="px-5 py-3 text-center font-bold font12 text-white click"
            style="background: #3e3e3e; border-radius: 0px 0px 6px 6px;"
          >
            <span>DEACTIVATE USER</span>
          </div>
          <div class="text-center">
            <p class="font12 text-grey font-bold click mt-5">Delete  Account</p>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12 sm-mt">
            <h3 class="font24 font-bold mb-4">Wallet History</h3>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Bank Account</th>
                    <th>Amount</th>
                    <th>Wallet Balance</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>63245678900 -Fidelity Bank</td>
                    <td>NGN 20,000</td>
                    <td>NGN 6000.00</td>
                    <td>Feb 12 2020</td>
                    <td class="text-green">Paid</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3 class="font24 font-bold mt-5 mb-4">Earnings</h3>
            <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Preview</th>
                      <th>Total Earning</th>
                      <th>Category</th>
                      <th>No of downloads</th>
                      <th>Date Added</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Boy sitting on swing and pla..</td>
                      <td>View</td>
                      <td>NGN 6000.00</td>
                      <td>@Rural</td>
                      <td>30</td>
                      <td>Feb 12 2020</td>
                      <td>
                        <span
                          class="mx-2"
                          data-toggle="modal"
                          data-target="#shareModal"
                        >
                          <img src="assets/img/share.png" alt="" />
                        </span>
                        <span
                          class="mx-2"
                          data-toggle="modal"
                          data-target="#deleteModal"
                        >
                          <img src="assets/img/delete.png" alt="" />
                        </span>
                        <span class="mx-2">
                          <img src="assets/img/arrow-down.png" alt="" />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Boy sitting on swing and pla..</td>
                      <td>View</td>
                      <td>NGN 6000.00</td>
                      <td>@Rural</td>
                      <td>30</td>
                      <td>Feb 12 2020</td>
                      <td>
                        <span class="mx-2">
                          <img src="assets/img/share.png" alt="" />
                        </span>
                        <span
                          class="mx-2"
                          data-toggle="modal"
                          data-target="#deleteModal"
                        >
                          <img src="assets/img/delete.png" alt="" />
                        </span>
                        <span class="mx-2">
                          <img src="assets/img/arrow-down.png" alt="" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </div>
      </div>
</section>