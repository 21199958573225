<app-navbar></app-navbar>
<section class="mt-5 mb-3 container">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="font26 font-bold text-grey mt-5 mb-3">Users</h3>
    <!--        <button class="btn bg-red text-white font14 border-radius">Remove</button>-->
  </div>
  <div class="row">
    <!-- <div
      class="
        text-green
        font12
        font-bold
        col-lg-2 col-sm-12
        sm-text-center sm-mt sm-mb-2
      "
    >
      ADD FILTER
    </div> -->
    <div class="table-search col-12">
      <input
        type="text"
        [(ngModel)]="name"
        placeholder="Search for customer by firstname"
      />
    </div>
    <!-- <div class="sort col-lg-3 col-sm-12 sm-mt sm-mb-2 sm-text-center">
      <span class="font-bold font14 text-grey mr-3">SORT BY</span>
      <span class="dropdown click">
        <span
          class="font-bold text-green font12 dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          >Name <i class="fa fa-chevron-down"></i
        ></span>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item click line-under">Name</a>
          <a class="dropdown-item click">name</a>
          <a class="dropdown-item click">name</a>
        </div>
      </span>
    </div> -->
  </div>
</section>

<section class="container">
  <div class="table-responsive mb-5">
    <table datatable class="table text-nowrap w-100 table-borderless">
      <thead class="text-grey">
        <tr>
          <th>S/N</th>
          <th>Customer Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <!--          <th>Downloaded</th>-->
          <th>Uploaded</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="font14 text-black">
        <tr
          class="click"
          *ngFor="
            let i = index;
            let user;
            of: users
              | filterPipe: name
              | paginate
                : {
                    id: 'listing_pagination',
                    itemsPerPage: 30,
                    currentPage: page,
                    totalItems: users.length
                  }
          "
        >
          <td>
            <!--          <label class="check-container">-->
            <!--            <input type="checkbox" />-->
            <!--            <span class="checkmark"></span>-->
            <!--          </label>-->
            {{ user.uid }}
          </td>
          <td>{{ user.firstname }} {{ user.surname }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <!--          <td class="text-center">-->
          <!--            3-->
          <!--          </td>-->
          <td class="text-center">
            {{ user.contribution_count }}
          </td>
          <td>
            <!--          <div class="btn btn-view text-black" routerLink="/user-details">-->
            <!--            View-->
            <!--          </div>-->
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ngb-pagination
    class="d-flex justify-content-center mb-5"
    [collectionSize]="users.length"
    [(page)]="page"
    [pageSize]="30"
    aria-label="Default pagination"
  ></ngb-pagination>

  <!-- <div class="d-flex justify-content-center my-5 list-nav" *ngIf="data">
    <div class="d-flex align-items-center sm-flex-column">
      <div class="prev font-bold font20 click" (click)="setPage(page - 1)">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="numbers sm-mr-none sm-ml-none">
        <span
          class="click"
          *ngIf="data.current_page - 4 > 0"
          (click)="setPage(data.current_page - 4)"
          >{{ data.current_page - 4 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 3 > 0"
          (click)="setPage(data.current_page - 3)"
          >{{ data.current_page - 3 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 2 > 0"
          (click)="setPage(data.current_page - 2)"
          >{{ data.current_page - 2 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page - 1 > 0"
          (click)="setPage(data.current_page - 1)"
          >{{ data.current_page - 1 }}</span
        >
        <span class="active">{{ data.current_page }}</span>
        <span
          class="click"
          *ngIf="data.current_page + 1 <= data.last_page"
          (click)="setPage(data.current_page + 1)"
          >{{ data.current_page + 1 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 2 <= data.last_page"
          (click)="setPage(data.current_page + 2)"
          >{{ data.current_page + 2 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 3 <= data.last_page"
          (click)="setPage(data.current_page + 3)"
          >{{ data.current_page + 3 }}</span
        >
        <span
          class="click"
          *ngIf="data.current_page + 4 <= data.last_page"
          (click)="setPage(data.current_page + 4)"
          >{{ data.current_page + 4 }}</span
        >
      </div>
      <div class="next font-bold font20 click" (click)="setPage(page + 1)">
        <i class="fa fa-angle-right"></i>
      </div>
    </div>
  </div> -->
</section>

<app-footer class="mt-auto"></app-footer>
