import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {AdminService} from "../../services/admin/admin.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-photos',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {

  images = [];

  constructor(
    public adminService : AdminService,
    public spinner: NgxSpinnerService,
    public toastr : ToastrService,
    public router : Router
  ) { }

  ngOnInit() {
    this.getImages();
  }


  getImages(){
    let data = {};
    this.spinner.show();

    this.adminService.images(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.images = success.data.images.data;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }


  delete(image){

    let r = confirm("Sure to delete?");
    if (r == true) {

      let data = {
        'imid' : image.imid
      };
      this.spinner.show();

      this.adminService.deleteImage(data).subscribe((success:any)=>{
        this.spinner.hide();

        this.getImages();

      },(error)=>{
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });

    }

  }

  goToDetails(image){
    this.adminService.image = image;
    this.router.navigate(['image/' + image.imid])
  }

}
