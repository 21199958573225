import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin/admin.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {

  images = [];
  constructor(
    public adminService : AdminService,
    public spinner: NgxSpinnerService,
    public toastr : ToastrService,
    public router : Router

  ) { }

  ngOnInit(): void {
    this.getImages();
  }


  getImages(){
    let data = {};
    this.spinner.show();

    this.adminService.pendingImages(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.images = success.data.images.data;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  goToDetails(image){
    this.adminService.image = image;
    this.router.navigate(['image/' + image.imid])
  }

}
