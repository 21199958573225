import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../../services/admin/admin.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  public admin:any = {};
  public loading:boolean = false;
  public returnUrl;

  constructor(
    private route:ActivatedRoute,
    public adminService:AdminService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);

    this.returnUrl = this.adminService.nextUrl != '' ?  this.adminService.nextUrl : '/dashboard';


  }

  signIn(){
    let data = this.admin;
    this.loading = true;
    this.adminService.signIn(data).subscribe((success:any)=>{
      this.loading = false;

      this.toastr.success(success.message);
      this.adminService.authToken = success.data.token;
      this.adminService.setAdmin(success.data.admin);
      this.router.navigateByUrl(this.returnUrl);
      this.adminService.nextUrl = null;

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }



}
