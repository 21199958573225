import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {

  transform(users: any, name: string): any {
    if(name === undefined){
      return users
    }
    return users.filter((user)=>{
      return user.firstname.toLowerCase().includes(name.toLowerCase()) || user.surname.toLowerCase().includes(name.toLowerCase())
    });
  }
}
