<app-navbar></app-navbar>
<section class="py-5 container">
  <h3 class="font24 text-grey font-bold">Images</h3>

  <div
    class="row align-items-center bg-white sm-reverse border-radius my-4 box-shadow"
  >
    <div
      class="text-green font12 font-bold col-lg-2 col-sm-12 sm-text-center sm-mt sm-mb-2"
    >
      ADD FILTER
    </div>
    <div class="table-search col-lg-7 col-sm-12">
      <input type="text" placeholder="Search Photos" />
    </div>
    <div class="sort col-lg-3 col-sm-12 sm-mt sm-mb-2 sm-text-center">
      <span class="font-bold font12 text-grey mr-3">SORT BY</span>
      <span class="dropdown click">
        <span
          class="font-bold font12 dropdown-toggle"
          id="dropdownMenuButton"
          data-toggle="dropdown"
        >Name <i class="fa fa-chevron-down"></i
        ></span>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item click line-under font12">Name</a>
          <a class="dropdown-item click  font12">Name</a>
        </div>
      </span>
    </div>
  </div>
</section>
<section class="container">

  <div class="row font14 bold font-lato search-body active">
    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let image of images" (click)="goToDetails(image)">
      <div class="bg-white my-4 border-radius box-shadow click" [routerLink]="['/image',image.imid]">
        <div class="img-box browse-image">
          <img [src]="image.preview" alt="" class='w-100'/>
        </div>
        <div class="p-3 d-flex align-items-center justify-content-between">
          <div>
            <label *ngIf="image.status == 'approved'" class="badge font14 p-2 font-light uppercase badge-success">{{image.status}}</label>
            <label *ngIf="image.status == 'rejected'" class="badge font14 p-2 font-light  badge-danger">{{image.status}}</label>
            <label *ngIf="image.status == 'pending'" class="badge font14 p-2 font-light uppercase badge-warning">{{image.status}}</label>

            <p>{{image.name}}</p>
            <p *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>
            <p *ngIf="image.user.brand">By {{image.user.brand}}</p>
          </div>
          <div>
            <img src="assets/img/Group.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center" style="min-height:50vh" *ngIf="images.length <= 0">No images for approval</div>
  </div>
</section>

<app-footer class="mt-auto"></app-footer>
