<div class="d-flex justify-content-center my-5 list-nav">
  <div class="d-flex align-items-center sm-flex-column">
    <div class="prev font-bold font20">
      <i class="fa fa-angle-left"></i>
    </div>
    <div class="numbers sm-mr-none sm-ml-none">
      <span *ngIf="(data.current_page - 4) > 0">{{data.current_page - 4}}</span>
      <span *ngIf="(data.current_page - 3) > 0">{{data.current_page - 3}}</span>
      <span *ngIf="(data.current_page - 2) > 0">{{data.current_page - 2}}</span>
      <span *ngIf="(data.current_page - 1) > 0">{{data.current_page - 1}}</span>
      <span class="active">{{data.current_page}}</span>
      <span *ngIf="(data.current_page + 1) <= data.last_page">{{data.current_page + 1}}</span>
      <span *ngIf="(data.current_page + 2) <= data.last_page">{{data.current_page + 2}}</span>
      <span *ngIf="(data.current_page + 3) <= data.last_page">{{data.current_page + 3}}</span>
      <span *ngIf="(data.current_page + 4) <= data.last_page">{{data.current_page + 4}}</span>
    </div>
    <div class="next  font-bold font20">
      <i class="fa fa-angle-right"></i>
    </div>
  </div>
</div>
