import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin/admin.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {IdashboardResponse} from "../../models/responses/IdashboardResponse";
import {Ipayment} from "../../models/Ipayment";
import {Iuser} from "../../models/Iuser";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  data: IdashboardResponse;
  payments: Ipayment[];
  users: Iuser[];

  constructor(
    public adminService : AdminService,
    public toastr : ToastrService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getDashboardData();
  }

  getDashboardData(){
    let data = {};
    this.spinner.show();
    this.adminService.dashboard(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.adminService.loading = false;

      this.data = success.data;
      this.payments = this.data.last_ten_payments;
      this.users = this.data.last_ten_users;

    },(error)=>{
      this.adminService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });
  }


}
