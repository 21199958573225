import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UsersComponent } from './pages/users/users.component';
import { UsersDetailsComponent } from './pages/users-details/users-details.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentDetailsComponent } from './pages/payment-details/payment-details.component';
import { ImagesComponent } from './pages/images/images.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import { SignInComponent } from './auth/sign-in/sign-in.component';
import {FormsModule} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HttpClientModule} from "@angular/common/http";
import {NgxSpinnerModule} from "ngx-spinner";
import {PaginatorComponent} from "./components/paginator/paginator.component";
import { SettingsComponent } from './pages/settings/settings.component';
import { ImageDetailsComponent } from './pages/image-details/image-details.component';
import { ApprovalComponent } from './pages/approval/approval.component';
import { FilterPipePipe } from './pipe/filter-pipe.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from "ngx-pagination"

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    DashboardComponent,
    UsersComponent,
    UsersDetailsComponent,
    PaymentComponent,
    PaymentDetailsComponent,
    ImagesComponent,
    SignInComponent,
    PaginatorComponent,
    SettingsComponent,
    ImageDetailsComponent,
    ApprovalComponent,
    FilterPipePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    MatProgressBarModule,
    FormsModule,
    NgxSpinnerModule,
    NgbModule,
    NgxPaginationModule
    // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
