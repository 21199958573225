<footer class="pt-4 bg-black">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 font-bold font14">
                <span class="text-white mr-1">Freebies</span>
                <span class="text-white mx-1">|</span>
                <span class="text-white mx-1">License</span>
                <span class="text-white mx-1">|</span>
                <span class="text-white ml-1">Sitemap</span>
                <p class="text-grey mt-3">2019.IQ Central Limited</p>
            </div>
            <div class="col-lg-6 col-sm-12 text-right sm-text-left text-black">
                <span class="mr-1 social-icon">
                    <i class="fa fa-twitter"></i>
                </span>
                <span class="mx-1 social-icon">
                    <i class="fa fa-youtube"></i>
                </span>
                <span class="mx-1 social-icon">
                    <i class="fa fa-facebook"></i>
                </span>
                <span class="mx-1 social-icon">
                    <i class="fa fa-pinterest"></i>
                </span>
                <span class="mx-1 social-icon">
                    <i class="fa fa-instagram"></i>
                </span>
                <span class="mr-2 text-grey">|</span>
                <span class="text-white">
                    <i class="fa fa-globe"></i>  English
                </span>
            </div>
        </div>
    </div>
</footer>