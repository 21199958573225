import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin/admin.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {Iuser} from "../../models/Iuser";
import {Ipaginate} from "../../models/Ipaginate";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users:[];
  data: Ipaginate;
  page: number = 1;
  name:string;

  constructor(
    public adminService : AdminService,
    public toastr : ToastrService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers(){
    let data = {
      page : this.page
    };
    this.spinner.show();

    this.adminService.users(data).subscribe((success: any) => {
      this.spinner.hide();
      console.log(success.data.users)
      this.data = success.data.users;
      this.page = this.data.current_page;
      this.users = success.data.users;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  setPage(page){
    this.page = page;
    this.getUsers();
  }

}
