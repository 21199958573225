
<section class="container py-5">
    <h3 class="py-5 font20 click" routerLink='/payment'> <i class="fa fa-arrow-left mr-3"></i> Back to Payments</h3>
    <div class="row">
        <div class="col-lg-3 col-sm-12">
          <div
            class="bg-black pb-3 pt-4 text-center"
            style="border-radius:6px 6px 0px 0px; "
          >
            <div class="profile-img mx-auto">
              <img src="assets/img/Oval.png" alt="" />
            </div>
            <p class="mt-2 font24 font-bold text-white ">Sarah<br />Shaibu</p>
          </div>
          <div
            class="px-5 py-3 text-center font-bold font12 text-white click"
            style="background: #3e3e3e; border-radius: 0px 0px 6px 6px;"
          >
            <span>DEACTIVATE USER</span>
          </div>
          <div class="text-center">
            <p class="font12 text-grey font-bold click mt-5">Delete  Account</p>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12 sm-mt">
            <div class="bg-white px-3 py-4 box-shadow">
                <p class="font18 font-bold mb-3">Payment Details</p>
                <div class="row my-2">
                    <div class="col-lg-3 col-sm-6">
                        <p class="font14 font-bold mb-0">Photo</p>
                        <p class="font-light">Boy Sitting on Swing..</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <button class="btn border-radius border-green text-green bg-white font14">View Photo</button>
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-lg-3 col-sm-6">
                        <p class="font14 font-bold mb-0">Photographer</p>
                        <p class="font-light">Sarah Shaibu</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <p class="font14 font-bold mb-0">Downloaded </p>
                        <p class="font-light">233</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <p class="font14 font-bold mb-0">Date Uploaded </p>
                        <p class="font-light">16-03-20</p>
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-lg-3 col-sm-6">
                        <p class="font14 font-bold mb-0">Paid</p>
                        <p class="font-light">Connie Wilson</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <p class="font14 font-bold mb-0">Amount </p>
                        <p class="font-light">N2000</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <p class="font14 font-bold mb-0">Date </p>
                        <p class="font-light">16-03-20</p>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <p class="font14 font-bold mb-0">Type </p>
                        <p class="font-light">Premium User</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
</section>