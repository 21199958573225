import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin/admin.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settings : any[];
  name = 'platform_percent';
  value;

  constructor(
    public adminService : AdminService,
    public spinner: NgxSpinnerService,
    public toastr : ToastrService
  ) { }

  ngOnInit(): void {
    this.getSettings();
  }

  selectName(event){
    this.name = event.target.value;
  }

  addSetting(){
    let data = {
      'name' : this.name,
      'value' : this.value
     };


    this.spinner.show();

    this.adminService.addSetting(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.name = '';
      this.value = '';

      this.getSettings();
      this.toastr.success(success.message);

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  getSettings(){
    let data = {};

    this.spinner.show();

    this.adminService.settings(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.settings = success.data.settings.data;
      console.log(success.data.settings.data);

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }


}
